import moment from 'moment';
import angular from 'angular';
import _ from 'lodash';

angular.module('portainer.docker').controller('DashboardController', [
  '$scope',
  '$q',
  '$interval',
  '$document',
  'Authentication',
  'ContainerService',
  'ImageService',
  'NetworkService',
  'VolumeService',
  'SystemService',
  'ServiceService',
  'StackService',
  'TaskService',
  'EndpointService',
  'ChartService',
  'Notifications',
  'EndpointProvider',
  'StateManager',
  'TagService',
  function (
    $scope,
    $q,
    $interval,
    $document,
    Authentication,
    ContainerService,
    ImageService,
    NetworkService,
    VolumeService,
    SystemService,
    ServiceService,
    StackService,
    TaskService,
    EndpointService,
    ChartService,
    Notifications,
    EndpointProvider,
    StateManager,
    TagService
  ) {
    $scope.dismissInformationPanel = function (id) {
      StateManager.dismissInformationPanel(id);
    };
    $scope.initialized = false;
    $scope.settings = {
      windowSeconds: 60 * 60 * 24 + '',
      liveupdate: false,
    };
    $scope.metricsControlsChanged = function () {
      if ($scope.settings.liveupdate) {
        $interval.cancel($scope.metricsInterval);
        // run immediately (to get the windowSeconds changes)
        startChartUpdate(parseInt($scope.settings.windowSeconds), $scope.charts, $scope.datasetTemplates);
        // start interval
        $scope.metricsInterval = $interval(function () {
          startChartUpdate(parseInt($scope.settings.windowSeconds), $scope.charts, $scope.datasetTemplates);
        }, 3000);
      } else {
        $interval.cancel($scope.metricsInterval);
        startChartUpdate(parseInt($scope.settings.windowSeconds), $scope.charts, $scope.datasetTemplates);
      }
    };

    function updateDataset(dataset, metricsResult, warningThreshold, errorThreshold) {
      var defaultColor = dataset.pointBackgroundColor;
      dataset.pointBackgroundColor = [];
      dataset.pointBorderColor = [];
      // add data (and set the colors of the points)
      dataset.data = metricsResult.values.map(function (e) {
        var v = parseFloat(e[1]);
        if (v > errorThreshold) {
          dataset.pointBackgroundColor.push('#dc3545');
          dataset.pointBorderColor.push('#dc3545');
        } else if (v > warningThreshold) {
          dataset.pointBackgroundColor.push('#ffc107');
          dataset.pointBorderColor.push('#ffc107');
        } else {
          dataset.pointBackgroundColor.push(defaultColor);
          dataset.pointBorderColor.push(defaultColor);
        }
        return v;
      });
    }

    function shortInstanceName(instanceName) {
      return instanceName.split('.').shift();
    }

    function updateChart(chart, datasetTemplate, metrics, warningThreshold, errorThreshold) {
      // create one dataset per result
      for (var i = 0; i < metrics.data.result.length; i++) {
        chart.data.datasets[i] = Object.assign({}, datasetTemplate);
        var instanceName = shortInstanceName(metrics.data.result[i].metric.instance);
        chart.data.datasets[i].label += ' (' + instanceName;
        // add mountpoint to label, if available
        if (metrics.data.result[i].metric.mountpoint) {
          chart.data.datasets[i].label += ', mountpoint ' + metrics.data.result[i].metric.mountpoint;
        }
        chart.data.datasets[i].label += ')';
        updateDataset(chart.data.datasets[i], metrics.data.result[i], warningThreshold, errorThreshold);
      }
      // set labels (from first metrics result)
      chart.data.labels = metrics.data.result[0].values.map(function (e) {
        var d = new Date(e[0] * 1000);
        return moment(d).format('HH:mm');
      });
      chart.update();
    }

    function updateBasicData(data) {
      $scope.metrics = {};
      data.data.result.forEach(function (result) {
        var name = result.metric.__name__;
        var instance = shortInstanceName(result.metric.instance);
        if (!$scope.metrics[instance]) {
          $scope.metrics[instance] = { cpuCores: 0, hdd: {} };
        }
        var mountpoint = result.metric.mountpoint;
        if (mountpoint) {
          if (!$scope.metrics[instance].hdd[mountpoint]) {
            $scope.metrics[instance].hdd[mountpoint] = { total: 0, free: 0 };
          }
        }
        if (name == 'node_cpu_seconds_total') {
          $scope.metrics[instance].cpuCores += 1;
        } else if (name == 'node_filesystem_size_bytes') {
          $scope.metrics[instance].hdd[mountpoint].total = parseInt(result.values[0][1], 10);
        } else if (name == 'node_filesystem_avail_bytes') {
          $scope.metrics[instance].hdd[mountpoint].free = parseInt(result.values[0][1], 10);
        } else if (name == 'node_memory_MemAvailable_bytes') {
          $scope.metrics[instance].ramFree = parseInt(result.values[0][1], 10);
        } else if (name == 'node_memory_MemTotal_bytes') {
          $scope.metrics[instance].ramTotal = parseInt(result.values[0][1], 10);
        } else if (name == 'node_load1') {
          $scope.metrics[instance].cpuLoad1 = parseFloat(result.values[0][1]);
        } else if (name == 'node_load5') {
          $scope.metrics[instance].cpuLoad5 = parseFloat(result.values[0][1]);
        } else if (name == 'node_load15') {
          $scope.metrics[instance].cpuLoad15 = parseFloat(result.values[0][1]);
        }
      });
    }

    function startChartUpdate(windowSeconds, charts, datasetTemplates) {
      $q.all({
        cpu: EndpointService.endpointMetrics('cpu%', windowSeconds, EndpointProvider.endpointID()),
        ram: EndpointService.endpointMetrics('mem%', windowSeconds, EndpointProvider.endpointID()),
        swap: EndpointService.endpointMetrics('swap%', windowSeconds, EndpointProvider.endpointID()),
        hdd: EndpointService.endpointMetrics('rootfs%', windowSeconds, EndpointProvider.endpointID()),
        basic: EndpointService.endpointMetricsData(EndpointProvider.endpointID()),
      })
        .then(function success(data) {
          updateChart(charts.cpu, datasetTemplates.cpu, data.cpu, 80, 90);
          updateChart(charts.ram, datasetTemplates.ram, data.ram, 80, 90);
          updateChart(charts.swap, datasetTemplates.swap, data.swap, 10, 20);
          updateChart(charts.hdd, datasetTemplates.hdd, data.hdd, 80, 90);
          updateBasicData(data.basic);
        })
        .catch(function error(err) {
          Notifications.error('Failure', err, 'Unable to retrieve endpoint metrics');
        });
    }

    function initCharts() {
      var cpuChart = ChartService.CreatePercentChart($('#cpuChart'), 'CPU busy');
      var ramChart = ChartService.CreatePercentChart($('#ramChart'), 'RAM used');
      var swapChart = ChartService.CreatePercentChart($('#swapChart'), 'Swap used');
      var hddChart = ChartService.CreatePercentChart($('#hddChart'), 'HDD used');
      var charts = {
        cpu: cpuChart,
        ram: ramChart,
        swap: swapChart,
        hdd: hddChart,
      };
      var datasetTemplates = {
        cpu: Object.assign({}, charts.cpu.data.datasets[0], { _meta: null }),
        ram: Object.assign({}, charts.ram.data.datasets[0], { _meta: null }),
        swap: Object.assign({}, charts.swap.data.datasets[0], { _meta: null }),
        hdd: Object.assign({}, charts.hdd.data.datasets[0], { _meta: null }),
      };
      $scope.charts = charts;
      $scope.datasetTemplates = datasetTemplates;
      startChartUpdate(parseInt($scope.settings.windowSeconds), charts, datasetTemplates);
    }
    $scope.offlineMode = false;
    $scope.showStacks = false;

    async function initView() {
      const endpointMode = $scope.applicationState.endpoint.mode;
      const endpointId = EndpointProvider.endpointID();
      $scope.endpointId = endpointId;

      $scope.showStacks = await shouldShowStacks();

      $q.all({
        containers: ContainerService.containers(1),
        images: ImageService.images(false),
        volumes: VolumeService.volumes(),
        networks: NetworkService.networks(true, true, true),
        services: endpointMode.provider === 'DOCKER_SWARM_MODE' && endpointMode.role === 'MANAGER' ? ServiceService.services() : [],
        stacks: StackService.stacks(true, endpointMode.provider === 'DOCKER_SWARM_MODE' && endpointMode.role === 'MANAGER', endpointId),
        tasks: TaskService.tasks(),
        info: SystemService.info(),
        endpoint: EndpointService.endpoint(endpointId),
        tags: TagService.tags(),
      })
        .then(function success(data) {
          $scope.containers = data.containers;
          $scope.images = data.images;
          $scope.volumeCount = data.volumes.length;
          $scope.networkCount = data.networks.length;
          $scope.serviceCount = data.services.length;
          $scope.cutoffHours = 2;
          $scope.rejectedServiceCount = 0;
          $scope.failedServiceCount = 0;
          var cutoff = new Date();
          cutoff.setHours(cutoff.getHours() - $scope.cutoffHours);
          data.tasks.forEach(function (t) {
            if (new Date(t.Updated) > cutoff) {
              if (t.Status && t.Status.State == 'rejected') {
                $scope.rejectedServiceCount += 1;
              } else if (t.Status && t.Status.State == 'failed') {
                $scope.failedServiceCount += 1;
              }
            }
          });
          $scope.stackCount = data.stacks.length;
          $scope.info = data.info;
          $scope.endpoint = data.endpoint;
          $scope.endpointTags = $scope.endpoint.TagIds.length
            ? _.join(
                _.filter(
                  _.map($scope.endpoint.TagIds, (id) => {
                    const tag = data.tags.find((tag) => tag.Id === id);
                    return tag ? tag.Name : '';
                  }),
                  Boolean
                ),
                ', '
              )
            : '-';
          $scope.offlineMode = EndpointProvider.offlineMode();

          // show old metrics if endpoint is tagged with "legacy-monitoring"
          $scope.showOldMetrics = false;
          $scope.newMetricsUrl = '/observe/#' + JSON.stringify({ filters: { endpoints: $scope.endpoint.Id, all: false } });
          if ($scope.endpointTags.indexOf('legacy-monitoring') !== -1) {
            $scope.showOldMetrics = true;
          }
          $scope.initialized = true;
          if ($scope.showOldMetrics) {
            $document.ready(function () {
              initCharts();
            });
          }
        })
        .catch(function error(err) {
          Notifications.error('Failure', err, 'Unable to load dashboard data');
        });
    }

    async function shouldShowStacks() {
      const isAdmin = Authentication.isAdmin();
      const { allowStackManagementForRegularUsers } = $scope.applicationState.application;

      return isAdmin || allowStackManagementForRegularUsers;
    }

    initView();
  },
]);
