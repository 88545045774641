const _paq = (window._paq = window._paq || []);
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// quantum
_paq.push(['setDocumentTitle', document.title]);
_paq.push(['trackPageView']);
// eof, quantum
_paq.push(['enableLinkTracking']);

// quantum
var u = 'https://stats.planetary-networks.de/';
// eof, quantum
_paq.push(['setTrackerUrl', u + 'matomo.php']);
// quantum
_paq.push(['setSiteId', '7']);
// eof, quantum
var d = document,
  g = d.createElement('script'),
  s = d.getElementsByTagName('script')[0];
g.type = 'text/javascript';
g.async = true;
// quantum
g.defer = true;
// eof, quantum
g.src = u + 'matomo.js';
s.parentNode.insertBefore(g, s);
